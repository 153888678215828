export type Sport = "nfl" | "ncaaf" | "mlb" | "nba" | "ncaam" | "nhl" | "wnba" | "ncaaw";


export enum GameStatus {
    TBD = "STATUS_TBD",
    SCHEDULED = "STATUS_SCHEDULED",
    IN_PROGRESS = "STATUS_IN_PROGRESS",
    FINAL = "STATUS_FINAL",
    FORFEIT = "STATUS_FORFEIT",
    CANCELLED = "STATUS_CANCELLED",
    POSTPONED = "STATUS_POSTPONED",
    DELAYED = "STATUS_DELAYED",
    SUSPENDED = "STATUS_SUSPENDED",
    FORFEIT_OF_HOME_TEAM = "STATUS_FORFEIT_OF_HOME_TEAM",
    FORFEIT_OF_AWAY_TEAM = "STATUS_FORFEIT_OF_AWAY_TEAM",
    RAIN_DELAY = "STATUS_RAIN_DELAY",
    BEGINNING_OF_PERIOD = "STATUS_BEGINNING_OF_PERIOD",
    END_OF_PERIOD = "STATUS_END_OF_PERIOD",
    END_PERIOD = "STATUS_END_PERIOD",
    HALFTIME = "STATUS_HALFTIME",
    OVERTIME = "STATUS_OVERTIME",
    FIRST_HALF = "STATUS_FIRST_HALF",
    SECOND_HALF = "STATUS_SECOND_HALF",
    ABANDONED = "STATUS_ABANDONED",
    FULL_TIME = "STATUS_FULL_TIME",
    RESCHEDULED = "STATUS_RESCHEDULED",
    START_LIST = "STATUS_START_LIST",
    INTERMEDIATE = "STATUS_INTERMEDIATE",
    UNOFFICIAL = "STATUS_UNOFFICIAL",
    MEDAL_OFFICIAL = "STATUS_MEDAL_OFFICIAL",
    GROUPINGS_OFFICIAL = "STATUS_GROUPINGS_OFFICIAL",
    PLAY_COMPLETE = "STATUS_PLAY_COMPLETE",
    OFFICIAL_EVENT_SHORTENED = "STATUS_OFFICIAL_EVENT_SHORTENED",
    CORRECTED_RESULT = "STATUS_CORRECTED_RESULT",
    RETIRED = "STATUS_RETIRED",
    BYE = "STATUS_BYE",
    WALKOVER = "STATUS_WALKOVER",
    VOID = "STATUS_VOID",
    PRELIMINARY = "STATUS_PRELIMINARY",
    GOLDEN_TIME = "STATUS_GOLDEN_TIME",
    SHOOTOUT = "STATUS_SHOOTOUT",
    FINAL_SCORE_AFTER_EXTRA_TIME = "STATUS_FINAL_SCORE_AFTER_EXTRA_TIME",
    FINAL_SCORE_AFTER_GOLDEN_GOAL = "STATUS_FINAL_SCORE_AFTER_GOLDEN_GOAL",
    FINAL_SCORE_AFTER_PENALTIES = "STATUS_FINAL_SCORE_AFTER_PENALTIES",
    END_EXTRA_TIME = "STATUS_END_EXTRA_TIME",
    EXTRA_TIME_HALF_TIME = "STATUS_EXTRA_TIME_HALF_TIME",
    FIXTURE_NO_LIVE_COVERAGE = "STATUS_FIXTURE_NO_LIVE_COVERAGE",
    FINAL_SCORE_ABANDONED = "STATUS_FINAL_SCORE_ABANDONED",
}

export abstract class SportScore {
    awayTeam: string;
    homeTeam: string;
    awayScore: number;
    homeScore: number;
    gameStatus: GameStatus;
    gameDate: string;
    seasonType: string;
    notes: string;
    lastPlay: string;

    constructor(awayTeam: string, homeTeam: string, awayScore: number, homeScore: number, gameStatus: GameStatus, gameDate: string, seasonType: string, notes: string, lastPlay: string) {
      this.awayTeam = awayTeam;
      this.homeTeam = homeTeam;
      this.awayScore = awayScore;
      this.homeScore = homeScore;
      this.gameStatus = gameStatus;
      this.gameDate = gameDate;
      this.seasonType = seasonType;
      this.notes = notes;
      this.lastPlay = lastPlay;
    }
    abstract getAsciiScoreboard(): string; //MAKE SURE TO ACCOUNT FOR OT

    convertToLocalTime(): string {
        // Ensure the date string is in the correct format
        if (!this.gameDate) {
            throw new Error("Invalid date string");
        }
    
        // Create a Date object from the UTC date string
        const date = new Date(this.gameDate);
    
        // Check if the Date object is valid
        if (isNaN(date.getTime())) {
            throw new Error("Invalid Date");
        }
    
        // Convert the Date object to a local time string
        const localTime = date.toLocaleString([], { 
            hour: 'numeric', 
            minute: '2-digit',
            hour12: true // Optional: to display time in 12-hour format
        });
    
        return localTime;
    }
    

    gameIsLive(): boolean {
        const notLiveStatuses = ["STATUS_FINAL", "STATUS_SCHEDULED", "STATUS_CANCELLED", "STATUS_POSTPONED"]
        return !notLiveStatuses.includes(this.gameStatus)
    }

    lastNameHelper(name?: string): string
    {
        let lastName = ""
        if (name)
        {

            const words = name.trim().split(/\s+/); // Split the name by spaces
            if (words.length <= 2) 
            {
              return words[words.length - 1]; // Return the last word if there are 2 or fewer words
            } 
            else 
            {
              return words.slice(1).join(' '); // Return all words except the first if there are more than 2
            }
        }
        else
        {
            return lastName;
        }

    }

    abstract formatLastPlay(): string;

}


export class FootballScore extends SportScore {
    time: string;
    quarter: number;
    awayPossession: boolean;
    ballOn: string;
    downAndDistance: string;
    // bigPlayStatus: string; //FG Y/N, INT, FUM, TD, SFTY.  this will be tough to get live, might need to calculate it  by last play, but it might have a shortdestiption
    //probably makes easier to do just the string if it's in a good format, to acc for goal to go
    // down: number;
    // distance: string;

  
    constructor(awayTeam: string, homeTeam: string, awayScore: number, homeScore: number, gameStatus: GameStatus, gameDate: string, seasonType: string, notes: string, lastPlay: string,
        time: string, quarter: number, awayPossession: boolean,
        ballOn: string, downAndDistance: string
    ) {
      super(awayTeam, homeTeam, awayScore, homeScore, gameStatus, gameDate, seasonType, notes, lastPlay);
      this.time = time;
      this.quarter = quarter;
      this.awayPossession = awayPossession;
      this.ballOn = ballOn;
      this.downAndDistance = downAndDistance;
      this.seasonType = seasonType;
      this.notes = notes;
    }

    getAsciiScoreboard(): string {
        const maxTeamNameLength = 4;
        const scoreWidth = 4;
        const boardWidth = maxTeamNameLength + scoreWidth + 28; // Padding for labels and possession marker

        const awayWinning = this.awayScore > this.homeScore;
        const awayScore = this.gameStatus === "STATUS_SCHEDULED" ? "" : (this.gameStatus === "STATUS_FINAL" && awayWinning ? 
                                                                    this.awayScore.toString() + " <" : 
                                                                        this.awayScore.toString() + `${this.awayPossession && this.gameStatus === "STATUS_IN_PROGRESS" ? " <>" : ""}`);
        const homeScore = this.gameStatus === "STATUS_SCHEDULED" ? "" : (this.gameStatus === "STATUS_FINAL" && !awayWinning &&  this.awayScore !== this.homeScore ? 
                                                                    this.homeScore.toString() + " <" : 
                                                                        this.homeScore.toString() + `${!this.awayPossession && this.gameStatus === "STATUS_IN_PROGRESS"  ? " <>" : ""}`);
        const awayScoreTextModifier = awayScore.includes(">") ? 3 : (awayScore.includes("<") ? 2 : 0);
        const homeScoreTextModifier = homeScore.includes(">") ? 3 : (homeScore.includes("<") ? 2 : 0);
        
        const gameSpecialStatus = this.gameStatus === "STATUS_IN_PROGRESS" ? this.formatLastPlay(): "";



        const fixedBigPlayTextPosition = 20;
        const awayTeamText = `${this.awayTeam.substring(0,4).padEnd(maxTeamNameLength)} | ${awayScore.toString().padStart(scoreWidth + awayScoreTextModifier)}`

        const bigPlayPadding= fixedBigPlayTextPosition > awayTeamText.length ? fixedBigPlayTextPosition - awayTeamText.length : 1;
        const awayTeamLine = `${awayTeamText}` + ' '.repeat(bigPlayPadding-3) + gameSpecialStatus;


        const homeTeamLine = `${this.homeTeam.substring(0,4).padEnd(maxTeamNameLength)} | ${homeScore.toString().padStart(scoreWidth + homeScoreTextModifier)}`;

        
        const timeQuarterLine = this.getTimeLine();
        
        const notesLine = this.notes;
        
        const border = "+".padEnd(boardWidth + 1, "-") + "+";
        const endCharacter = this.gameIsLive()  ? "" : "|";
        const downAndDistance = this.gameStatus === "STATUS_IN_PROGRESS" ? `${this.downAndDistance}` : " ".repeat(13); 
        const ballOn = this.gameStatus === "STATUS_IN_PROGRESS" ? " ".repeat(20 - timeQuarterLine.length) + this.ballOn : "".padEnd(boardWidth-timeQuarterLine.length-1);
        const downAndDistanceLine = "|" + `${" ".repeat(21)}${downAndDistance}` + `  ${endCharacter}`;
        
        const paddingLine = "|" + " ".padEnd(boardWidth) + `${endCharacter}`;

        //MAKE SURE TO ACCOUNT FOR OT
        return [
            notesLine,
            border,
            paddingLine,
            `| ${awayTeamLine.padEnd(boardWidth-2)} ${endCharacter}`,
            `| ${homeTeamLine.padEnd(boardWidth-2)} ${endCharacter}`,
            downAndDistanceLine,
            `| ${timeQuarterLine}` + `${ballOn}` +  `${endCharacter}`,
            border,
        ].join("\n");
    }

    redZone(): boolean {
        return true;
    }

    getTimeLine(): string {
        switch (this.gameStatus)
        {
            case "STATUS_FINAL":
                // return `FINAL ${this.inning > 8 ? `[${10}]`: null}`;
                return `FINAL${this.quarter > 4 ? ` (${this.quarter > 5 ? this.quarter - 4 : ""}OT)`: ""}`;
            case "STATUS_SCHEDULED":
                return this.convertToLocalTime();
            case "STATUS_HALFTIME":
                return "HT";
            case "STATUS_END_PERIOD":
            case "STATUS_END_OF_PERIOD":
                    return `END ${this.quarter > 4 ? `${this.quarter > 5 ? this.quarter - 4 : ""}OT`: `Q${this.quarter}`}`
            case "STATUS_IN_PROGRESS":
                {   
                    const overtime = this.quarter > 4;
                    const OTValue = this.quarter - 4;
                    return !overtime ? `Q${this.quarter} ${this.time}` : `${OTValue> 1 ? OTValue : ""}OT ${this.time}`
                }
            default:
                return this.gameStatus.replace("STATUS_", "").replaceAll("_", " ");
            
        }
    }

    static fromObject(game: any): FootballScore 
        {
        return new FootballScore(game.awayTeam, game.homeTeam, game.awayScore, game.homeScore, game.gameStatus, game.gameDate, game.seasonType, game.notes, game.lastPlay, 
            game.time, game.quarter, game.awayPossession, game.ballOn, game.downAndDistance);
    }
    
    formatLastPlay(): string {
        let workingString = this.lastPlay;
       
        switch (workingString)
        {
          case "Passing Touchdown":
            workingString = "PASS TD!";
            break;
          case "Rushing Touchdown":
            workingString = "RUSH TD!";
            break;
          case "Pass Incompletion":
            workingString = "Pass: Incomplete"
            break;
          case "Extra Point Good":
            workingString = "XP Made";
            break;
          case "Extra Point Missed":
            workingString = "XP Missed";
            break;
          case "Field Goal Good":
            workingString = "FG Made";
            break;
          case "Field Goal Missed":
            workingString = "FG Missed";
            break; 
          case "Interception Return Touchdown":
            workingString = "PICK 6!"
            break;
          case "Pass Interception Return":
            workingString = "Interception"
            break;
          case "Fumble Recovery (Opponent)":
            workingString = "Fumble Lost"
            break;
          case "Fumble Recovery (Own)":
            workingString = "Fumble Recovered"
            break;
          case "End Period":
            workingString = "";
            break;
          case "End of Half":
            workingString = "";
            break;
          case "End of Game":
            workingString = "";
            break;
        case "Two-minute warning":
            workingString = "2 Min Warning"
            break;
          case "Timeout":
          case "Penalty":
          case "Official Timeout":
          case "Punt":
          case "Kickoff":
          case "Sack":
          case "Safety":
          case "Coin Toss":
            break;
          default:
            console.log(workingString);
            break;
          
        }

        if (workingString.includes("Fumble") && workingString.includes("Touchdown"))
        {
            workingString = "FUMBLE 6!";
        }

        // return "PICK 6!";
        return workingString.substring(0,16);
        
    }
    

    
}


export class MLBScore extends SportScore {
    inning: number;
    isTopOfInning: boolean;
    balls: number;
    strikes: number;
    outs: number;
    firstBase: boolean;
    secondBase: boolean;
    thirdBase: boolean;
    pitcher?: string
    batter?: string


  
    constructor(
        awayTeam: string, homeTeam: string, awayScore: number, homeScore: number, gameStatus: GameStatus, gameDate: string, seasonType: string, notes: string, lastPlay: string,
        inning: number, isTopOfInning: boolean, balls: number, strikes: number, outs: number, firstBase: boolean, secondBase: boolean, thirdBase: boolean, pitcher: string = "", batter: string = ""
    ) {
        super(awayTeam, homeTeam, awayScore, homeScore, gameStatus, gameDate, seasonType, notes, lastPlay);
        this.inning = inning;
        this.isTopOfInning = isTopOfInning;
        this.balls = balls;
        this.strikes = strikes;
        this.outs = outs;
        this.firstBase = firstBase;
        this.secondBase = secondBase;
        this.thirdBase = thirdBase;
        this.pitcher = pitcher;
        this.batter = batter;
    }
    getAsciiScoreboard(): string {
        const maxTeamNameLength = 4;
        const scoreWidth = 4;
        const boardWidth = maxTeamNameLength + scoreWidth + 28;

        const firstBaseIcon = this.gameStatus === "STATUS_IN_PROGRESS" ? (this.firstBase ? "◆" : "◇") : "";
        const secondBaseIcon = this.gameStatus === "STATUS_IN_PROGRESS" ? (this.secondBase ? "◆" : "◇") : "";
        const thirdBaseIcon = this.gameStatus === "STATUS_IN_PROGRESS" ? (this.thirdBase ? "◆" : "◇") : "";
        const homePlateIcon = this.gameStatus === "STATUS_IN_PROGRESS" ? (this.inning < 10 ? " ◆" : "◆")  : "";

        const ballsAndStrikes = this.gameStatus === "STATUS_IN_PROGRESS" ? `${this.balls} - ${this.strikes}`: "     " ;
        const pitcherText = this.gameStatus === "STATUS_IN_PROGRESS" && this.pitcher !== "" ? `P: ${this.lastNameHelper(this.pitcher)}` : " ";
        const batterText = this.gameStatus === "STATUS_IN_PROGRESS" && this.batter !== "" ? `H: ${this.lastNameHelper(this.batter)}` : " ";

        const awayWinning = this.awayScore > this.homeScore;
        const awayScore = this.gameStatus === "STATUS_SCHEDULED" ? "" : (this.gameStatus === "STATUS_FINAL" && awayWinning ? this.awayScore.toString() + " <" : this.awayScore.toString());
        const homeScore = this.gameStatus === "STATUS_SCHEDULED" ? "" : (this.gameStatus === "STATUS_FINAL" && !awayWinning ? this.homeScore.toString() + " <" : this.homeScore.toString());
        const awayScoreTextModifier = awayScore.includes("<") ? 2 : 0;
        const homeScoreTextModifier = homeScore.includes("<") ? 2 : 0;

        const lastPlayTextAway = this.isTopOfInning ? " ".repeat(1) + this.formatLastPlay() : " ".repeat(6);
        const lastPlayTextHome = !this.isTopOfInning ? " ".repeat(1) + this.formatLastPlay() : " ".repeat(3 + this.formatLastPlay().length);
        
        const fixedBatterTextPosition = 20;
        const awayTeamText = `${this.awayTeam.substring(0,4).padEnd(maxTeamNameLength)} | ${awayScore.toString().padStart(scoreWidth + awayScoreTextModifier)}`

        const batterTextPadding= fixedBatterTextPosition > awayTeamText.length ? fixedBatterTextPosition - awayTeamText.length : 1;
        const awayTeamLine = `${awayTeamText}` + lastPlayTextAway + ' '.repeat(3) + batterText;//`${batterText.padStart(batterTextPadding)}`;
        const homeTeamLine = `${this.homeTeam.substring(0,4).padEnd(maxTeamNameLength)} | ${homeScore.toString().padStart(scoreWidth + homeScoreTextModifier)}${lastPlayTextHome.padEnd(12)}${secondBaseIcon}`; //14 spaces
        const inningLine =  this.getInningLine(homePlateIcon);

        const notesLine = this.notes;

        const border = "+".padEnd(boardWidth + 1, "-") + "+";
        const endCharacter = this.gameIsLive()  ? "" : "|";

        const pitcherTextOrBlank = this.gameStatus === "STATUS_IN_PROGRESS" ? ' '.repeat(fixedBatterTextPosition+1) + pitcherText : " ".padStart(boardWidth);

        const paddingLineBlank = "|" + pitcherTextOrBlank + `${endCharacter}`;

        const paddingLineBases = "|" + `             ${ballsAndStrikes}    ${thirdBaseIcon}   ${firstBaseIcon}` + `       ${this.gameStatus === "STATUS_IN_PROGRESS" ? "": " ".repeat(4)}${endCharacter}`;


        return [
            notesLine,
            border,
            paddingLineBlank,
            `| ${awayTeamLine.padEnd(boardWidth-2)} ${endCharacter}`, 
            `| ${homeTeamLine.padEnd(boardWidth-2)} ${endCharacter}`,
            paddingLineBases,
            `| ${inningLine.padEnd(boardWidth-2)} ${endCharacter}`,
            border
        ].join("\n");
    }

    getInningLine(homePlateIcon: string): string {
        switch (this.gameStatus)
        {
            case "STATUS_FINAL":
                // return `FINAL ${this.inning > 8 ? `[${10}]`: null}`;
                return `FINAL${this.inning > 9 ? ` (${this.inning})`: ""}`;
            case "STATUS_SCHEDULED":
                return this.convertToLocalTime();
            case "STATUS_END_PERIOD":
                const endText = `END ${this.inning}`
                return `${endText}`
            case "STATUS_IN_PROGRESS":
                const inningText = `${this.isTopOfInning ? "▲" : "▼"} ${this.inning}`
                const outText = `${" ".repeat(12- (inningText.length))}${this.outs} Out`
                const combinedText = `${inningText}${outText}`
                const paddingCount = this.inning > 9 ? 23 : 22;
                return `${combinedText.padEnd(paddingCount)}${homePlateIcon}`;
            case "STATUS_RAIN_DELAY":
                const inningTextDelay = `${this.isTopOfInning ? "▲" : "▼"} ${this.inning}`
                return `${inningTextDelay} (RAIN DELAY)`
            default:
                return this.gameStatus.replace("STATUS_", "").replaceAll("_", " ");

        }
    }

    static fromObject(game: any): MLBScore {
        const topOfInning = (game.inningStatus as string).toLocaleLowerCase().includes("top") || (game.inningStatus as string).toLocaleLowerCase().includes("end");
        return new MLBScore(
          game.awayTeam, game.homeTeam, game.awayScore, game.homeScore, game.gameStatus, game.gameDate, game.seasonType, game.notes, game.lastPlay,
          game.inning, topOfInning, game.balls, game.strikes, game.outs, game.firstBase, game.secondBase, game.thirdBase, game.pitcher, game.batter
        );
    }

    formatLastPlay(): string {
        let workingString = this.lastPlay;
        if (this.lastPlay != "")
        {
            console.log(`mlb game: ${this.awayTeam} v ${this.homeTeam} ${this.isTopOfInning ? "top" : "bot"} ${this.inning}:`, this.lastPlay);
        }   
        
        const validPlays = ["1B", "2B", "3B", "HBP"]

        switch (this.lastPlay)
        {
            case "HR":
                workingString =  "HR!!!";
                break;
            case "SS":
                workingString = "K";
                break;
            case "SL":
                workingString = "ꓘ"
                break;
            case "B":
                workingString = "WALK"
                break;
            case "SF":
                workingString = "SAC"
                break;
            case "SB":
                workingString = "STEAL"
                break;
            default:
                {
                    //caught stealing, pickoff caught stealing
                    if (this.lastPlay.includes("CS"))
                    {
                        workingString = "CS";
                        break;
                    }

                    if (!validPlays.includes(this.lastPlay))
                    {
                        workingString = "";
                    }
                }
        }

        return workingString.substring(0,5).padEnd(5);
        
    }
}


export class NBAScore extends SportScore {
    time: string;
    quarter: number;
  
    constructor(awayTeam: string, homeTeam: string, awayScore: number, homeScore: number, gameStatus: GameStatus, gameDate: string, seasonType: string, notes: string, lastPlay: string,
        time: string, quarter: number) {
      super(awayTeam, homeTeam, awayScore, homeScore, gameStatus, gameDate, seasonType, notes, lastPlay);
      this.time = time;
      this.quarter = quarter;
      this.lastPlay = lastPlay;
    }
    

    getAsciiScoreboard(): string {
        const maxTeamNameLength = 4;
        const scoreWidth = 4;
        const boardWidth = maxTeamNameLength + scoreWidth + 28; // Padding for labels and possession marker

        const awayWinning = this.awayScore > this.homeScore;
        const awayScore = this.gameStatus === "STATUS_SCHEDULED" ? "" : (this.gameStatus === "STATUS_FINAL" && awayWinning ? 
                                                                    this.awayScore.toString() + " <" : 
                                                                        this.awayScore.toString());
        const homeScore = this.gameStatus === "STATUS_SCHEDULED" ? "" : (this.gameStatus === "STATUS_FINAL" && !awayWinning ? 
                                                                    this.homeScore.toString() + " <" : 
                                                                        this.homeScore.toString());
        const awayScoreTextModifier = awayScore.includes("<") ? 2 : 0;
        const homeScoreTextModifier = homeScore.includes("<") ? 2 : 0;
        
        const gameSpecialStatus = this.gameStatus === "STATUS_IN_PROGRESS" ? " ".repeat(2) + this.formatLastPlay(): "";

        const fixedLastBasketTextPosition = 20;
        const awayTeamText = `${this.awayTeam.substring(0,4).padEnd(maxTeamNameLength)} | ${awayScore.toString().padStart(scoreWidth + awayScoreTextModifier)}`

        const bigPlayPadding= fixedLastBasketTextPosition > awayTeamText.length ? fixedLastBasketTextPosition - awayTeamText.length : 1;
        const awayTeamLine = `${awayTeamText}` + ' '.repeat(bigPlayPadding);


        const homeTeamLine = `${this.homeTeam.substring(0,4).padEnd(maxTeamNameLength)} | ${homeScore.toString().padStart(scoreWidth + homeScoreTextModifier)}`;

        
        const timeQuarterLine = this.getTimeLine();

        const notesLine = this.notes;

        const border = "+".padEnd(boardWidth + 1, "-") + "+";
        const endCharacter = this.gameIsLive()  ? "" : "|";
        const lastBasketLine = "|" + gameSpecialStatus.padStart(boardWidth) + `${endCharacter}`;
        const paddingLine = "|" + " ".padEnd(boardWidth) + `${endCharacter}`;
        //MAKE SURE TO ACCOUNT FOR OT
        return [
            notesLine,
            border,
            paddingLine,
            `| ${awayTeamLine.padEnd(boardWidth-2)} ${endCharacter}`,
            `| ${homeTeamLine.padEnd(boardWidth-2)} ${endCharacter}`,
            lastBasketLine,
            `| ${timeQuarterLine.padEnd(boardWidth-2)} ${endCharacter}`,
            border
        ].join("\n");
    }

    getTimeLine(): string {
        switch (this.gameStatus)
        {
            case "STATUS_FINAL":
                // return `FINAL ${this.inning > 8 ? `[${10}]`: null}`;
                return `FINAL${this.quarter > 4 ? ` (${this.quarter > 5 ? this.quarter - 4 : ""}OT)`: ""}`;
            case "STATUS_SCHEDULED":
                return this.convertToLocalTime();
            case "STATUS_HALFTIME":
                return "HT";
            case "STATUS_END_PERIOD":
            case "STATUS_END_OF_PERIOD":
                return `END ${this.quarter > 4 ? `${this.quarter > 5 ? this.quarter - 4 : ""}OT`: `Q${this.quarter}`}`
            case "STATUS_IN_PROGRESS":
                {   
                    const overtime = this.quarter > 4;
                    const OTValue = this.quarter - 4;
                    return !overtime ? `Q${this.quarter} ${this.time}` : `${OTValue> 1 ? OTValue : ""}OT ${this.time}`
                }
            default:
                return this.gameStatus.replace("STATUS_", "").replaceAll("_", " ");
        }
    }

    static fromObject(game: any): NBAScore 
    {
        return new NBAScore(game.awayTeam, game.homeTeam, game.awayScore, game.homeScore, game.gameStatus, game.gameDate, game.seasonType, game.notes, game.lastPlay, 
                     game.time, game.quarter);
    }
    
    formatLastPlay(): string {
        // const scoringPlays = ["3pt"]
        // if (!this.lastPlay)
        // {
        //     return "";
        // }
        // if (this.lastPlay.includes("3PT") || this.lastPlay.includes("2PT"))
        // {
        //     return this.lastPlay;
        // }
        if (this.lastPlay != "")
        {
            console.log(`nba game: ${this.awayTeam} v ${this.homeTeam} Q${this.quarter} ${this.time}:`, this.lastPlay);
        }   
        // const play = "2PT PURD - Gilgeous-Alexander";
        return this.lastPlay;
    }
}


export class NCAABScore extends SportScore {
    time: string;
    half: number;
    lastPlay: string;

    constructor(awayTeam: string, homeTeam: string, awayScore: number, homeScore: number, gameStatus: GameStatus, gameDate: string, seasonType: string, notes: string, lastPlay: string,
        time: string, half: number) 
    {
        super(awayTeam, homeTeam, awayScore, homeScore, gameStatus, gameDate, seasonType, notes, lastPlay);
        this.time = time;
        this.half = half;
        this.lastPlay = lastPlay;
    }

    getAsciiScoreboard(): string {
        const maxTeamNameLength = 4;
        const scoreWidth = 4;
        const boardWidth = maxTeamNameLength + scoreWidth + 28; // Padding for labels and possession marker

        const awayWinning = this.awayScore > this.homeScore;
        const awayScore = this.gameStatus === "STATUS_SCHEDULED" ? "" : (this.gameStatus === "STATUS_FINAL" && awayWinning ? this.awayScore.toString() + " <" : this.awayScore.toString());
        const homeScore = this.gameStatus === "STATUS_SCHEDULED" ? "" : (this.gameStatus === "STATUS_FINAL" && !awayWinning ? this.homeScore.toString() + " <" : this.homeScore.toString());
        const awayScoreTextModifier = awayScore.includes("<") ? 2 : 0;
        const homeScoreTextModifier = homeScore.includes("<") ? 2 : 0;
        
        const gameSpecialStatus = this.gameStatus === "STATUS_IN_PROGRESS" ? " ".repeat(2) + this.formatLastPlay(): "";


        const awayTeamLinePadding = 20;
        const awayTeamText = `${this.awayTeam.substring(0,4).padEnd(maxTeamNameLength)} | ${awayScore.toString().padStart(scoreWidth + awayScoreTextModifier)}`

        const extraPaddignDeprecated= awayTeamLinePadding > awayTeamText.length ? awayTeamLinePadding - awayTeamText.length : 1;
        const awayTeamLine = `${awayTeamText}` + ' '.repeat(extraPaddignDeprecated);


        const homeTeamLine = `${this.homeTeam.substring(0,4).padEnd(maxTeamNameLength)} | ${homeScore.toString().padStart(scoreWidth + homeScoreTextModifier)}`;

        
        const timeQuarterLine = this.getTimeLine();

        const notesLine = this.notes;

        const border = "+".padEnd(boardWidth + 1, "-") + "+";
        const endCharacter = this.gameIsLive()  ? "" : "|";
        const lastBasketLine = "|" + gameSpecialStatus.padStart(boardWidth) + `${endCharacter}`;

        const paddingLine = "|" + " ".padEnd(boardWidth) + `${endCharacter}`;
        //MAKE SURE TO ACCOUNT FOR OT
        return [
            notesLine,
            border,
            paddingLine,
            `| ${awayTeamLine.padEnd(boardWidth-2)} ${endCharacter}`,
            `| ${homeTeamLine.padEnd(boardWidth-2)} ${endCharacter}`,
            lastBasketLine,
            `| ${timeQuarterLine.padEnd(boardWidth-2)} ${endCharacter}`,
            border
        ].join("\n");
    }

    getTimeLine(): string {

        switch (this.gameStatus)
        {
            case "STATUS_FINAL":
                // return `FINAL ${this.inning > 8 ? `[${10}]`: null}`;
                return `FINAL${this.half > 2 ? ` (${this.half - 3 === 0 ? "" : this.half-2}OT)`: ""}`;
            case "STATUS_SCHEDULED":
                return this.convertToLocalTime();
            case "STATUS_HALFTIME":
                return "HT";
            case "STATUS_END_PERIOD":
            case "STATUS_END_OF_PERIOD":
                return `END ${this.half > 2 ? `${this.half > 3 ? this.half - 2 : ""}OT`: `${this.half}H`}`
            case "STATUS_IN_PROGRESS":
                return `${this. half > 2 ? `${this.half-2 > 1 ? this.half-2 : ""}OT`: `${this.half}H`} ${this.time}`;
            default:
                return this.gameStatus.replace("STATUS_", "").replaceAll("_", " ");       
                
        }
    }

    static fromObject(game: any): NCAABScore 
    {
        return new NCAABScore(game.awayTeam, game.homeTeam, game.awayScore, game.homeScore, game.gameStatus, game.gameDate, game.seasonType, game.notes, game.lastPlay,
            game.time, game.half)
    }

    formatLastPlay(): string {
    if (this.lastPlay != "")
        {
            console.log(`ncaab game: ${this.awayTeam} v ${this.homeTeam} ${this.half}H ${this.time}:`, this.lastPlay);
        }   
        // const play = "2PT PURD - Antetokounmpo";
        // return play.substring(0, 35)
        return (this.lastPlay.substring(0,35))
    }
}

export class NHLScore extends SportScore {
    time: string;
    period: number;
    awayPowerPlay: boolean;
    homePowerPlay: boolean;

  
    constructor(awayTeam: string, homeTeam: string, awayScore: number, homeScore: number, gameStatus: GameStatus, gameDate: string, seasonType: string, notes: string, lastPlay: string,
        time: string, period: number, awayPowerPlay: boolean, homePowerPlay: boolean) {
      super(awayTeam, homeTeam, awayScore, homeScore, gameStatus, gameDate, seasonType, notes, lastPlay);
      this.time = time;
      this.period = period;
      this.awayPowerPlay = awayPowerPlay;
      this.homePowerPlay = homePowerPlay;
    }

    //MAKE SURE TO ACCOUNT FOR OT
    getAsciiScoreboard(): string {
        const maxTeamNameLength = 4;
        const scoreWidth = 4;
        const boardWidth = maxTeamNameLength + scoreWidth + 28;

        const awayWinning = this.awayScore > this.homeScore;
        const awayScore = this.gameStatus === "STATUS_SCHEDULED" ? "" : (this.gameStatus === "STATUS_FINAL" && awayWinning ? 
                            this.awayScore.toString() + " <" : 
                                this.awayScore.toString() + `${this.awayPowerPlay && this.gameStatus === "STATUS_IN_PROGRESS" ? " PP" : ""}`);
        const homeScore = this.gameStatus === "STATUS_SCHEDULED" ? "" : (this.gameStatus === "STATUS_FINAL" && !awayWinning &&  this.awayScore !== this.homeScore ? 
                            this.homeScore.toString() + " <" : 
                                this.homeScore.toString() + `${this.homePowerPlay && this.gameStatus === "STATUS_IN_PROGRESS"  ? " PP" : ""}`);
        const ppRegex = /(?<![a-zA-Z])\bPP\b(?![a-zA-Z])/g;                                
        const awayScoreTextModifier = awayScore.match(ppRegex) ? 3 : (awayScore.includes("<") ? 2 : 0);
        const homeScoreTextModifier = homeScore.match(ppRegex) ? 3 : (homeScore.includes("<") ? 2 : 0);
        
        const gameSpecialStatus = this.gameStatus === "STATUS_IN_PROGRESS" ? this.formatLastPlay(): "";



        const fixedLastBasketTextPosition = 20;
        const awayTeamText = `${this.awayTeam.substring(0,4).padEnd(maxTeamNameLength)} | ${awayScore.toString().padStart(scoreWidth + awayScoreTextModifier)}`

        const awayTeamLine = `${awayTeamText.padEnd(22)}` + gameSpecialStatus ;


        const homeTeamLine = `${this.homeTeam.padEnd(maxTeamNameLength)} | ${homeScore.toString().padStart(scoreWidth + homeScoreTextModifier)}`;

        const timePeriodLine = this.getTimeLine();
        
        const endCharacter = this.gameIsLive()  ? "" : "|";

        const notesLine = this.notes;
        const border = "+".padEnd(boardWidth + 1, "-") + "+";
        const paddingLine = "|" + " ".padEnd(boardWidth) + `${endCharacter}`;


        return [
            notesLine,
            border,
            paddingLine,
            `| ${awayTeamLine.padEnd(boardWidth-2)} ${endCharacter}`,
            `| ${homeTeamLine.padEnd(boardWidth-2)} ${endCharacter}`,
            paddingLine,
            `| ${timePeriodLine.padEnd(boardWidth-2)} ${endCharacter}`,
            border
        ].join("\n");
    }

    getTimeLine(): string {

        const postSeason = this.seasonType === "post-season";

        switch (this.gameStatus)
        {
            case "STATUS_FINAL":
                {
                    
                    let finalExtra = ""
                    if (postSeason)
                    {
                        finalExtra = `${this.period > 3 ? 
                            ` (${this.period - 4 === 0 ? "" : this.period-3}OT)`: 
                                ""}`;
                    } 
                    //shootout logic
                    else
                    {
                        const shootout = this.period === 5;
                        finalExtra = `${this.period > 3 ?
                             ` (${shootout ? "SO" : "OT"})`: 
                                ""}`;
                    }
                    return `FINAL${finalExtra}`;
                }
            case "STATUS_SCHEDULED":
                return this.convertToLocalTime();
            case "STATUS_HALFTIME":
                return "HT";
            case "STATUS_END_PERIOD":
            case "STATUS_END_OF_PERIOD":
                {
                    let endPeriod = ""
                    if (postSeason)
                    {
                        endPeriod = `${this.period > 3 ? 
                            `${this.period - 4 === 0 ? "" : this.period-3}OT`: 
                                `${this.period}P`}`;
                    } 
                    //shootout logic
                    else
                    {
                        const shootout = this.period === 5;
                        endPeriod = `${this.period > 3 ?
                             `${shootout ? "SO" : "OT"}`: 
                                `${this.period}P`}`;
                    }
                    return `END ${endPeriod}`

                }
            case "STATUS_IN_PROGRESS":
                {
                    let scoreLine = "";
                    if (postSeason)
                    {
                        const overtimeText = `${this.period === 4 ? "" : this.period - 3}OT ${this.time}`;
                        const regulationText = `${this.period}P ${this.time}`; 
                        scoreLine = this.period < 4 ? regulationText : overtimeText;

                    }   
                    else
                    {
                        const overtimeText = `OT ${this.time}`;
                        const shootoutText  = "SO";
                        const regulationText = `${this.period}P ${this.time}`;
                        scoreLine = this.period < 4 ? regulationText : (this.period === 4 ? overtimeText : shootoutText);
                    }
                    return `${scoreLine}`;
                }
            default:
                return this.gameStatus.replace("STATUS_", "").replaceAll("_", " ");       
                
        }
    }


    static fromObject(game: any): NHLScore 
    {
        return new NHLScore(game.awayTeam, game.homeTeam, game.awayScore, game.homeScore, game.gameStatus, game.gameDate, game.seasonType, game.notes, game.lastPlay,
             game.time, game.period, game.awayPowerPlay, game.homePowerPlay);
    }

    formatLastPlay(): string {
        if (this.awayPowerPlay && this.homePowerPlay)
        {
            //4v4
        }
        // return "GOAL!";
        if (this.lastPlay != "")
        {
            console.log(`nhl game: ${this.awayTeam} v ${this.homeTeam} ${this.period}P ${this.time}:`, this.lastPlay);
        }   
        if (this.lastPlay === "Goal")
        {
            return "GOAL!";
        }
        return this.lastPlay;
    }
}
